'use client';

import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useIsMounted } from 'usehooks-ts';

import { type User } from '@/features/user/types/user-types';

let oldPath = '';

const watchPath = (path: string) => {
  if (oldPath === path) return;

  oldPath = path;
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

interface GoogleAnalyticProps {
  loggedUser: User | null;
}

export default function GoogleAnalytic(props: GoogleAnalyticProps) {
  const { loggedUser } = props;
  const isMounted = useIsMounted();

  useEffect(() => {
    ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID, { debug: process.env.NEXT_PUBLIC_GOOGLE_ANALYTIC_DEBUG === 'true' });

    if (loggedUser) {
      ReactGA.set({ userId: loggedUser.id });
    }

    watchPath(window.location.pathname);
  }, [isMounted, loggedUser]);

  return '';
}
